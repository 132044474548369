export * from '@oracle-cx-commerce/endpoints';
export const GetAudienceMembership = () => import('./GetAudienceMembership');
export const _existingEmailCheckEndpoint = () => import('./existingEmailCheckEndpoint');
export const listProjects = () => import('./project-planner/listProjects');
export const generateProjectPDF = () => import('./project-planner/generateProjectPDF');
export const saveProjectPlanner = () => import('./project-planner/saveProjectPlanner');
export const listContacts = () => import('./project-planner/listContacts');
export const createContact = () => import('./project-planner/createContact');
export const updateContact = () => import('./project-planner/updateContact');
export const deleteContact = () => import('./project-planner/deleteContact');
export const getProjectDetails = () => import('./project-planner/getProjectDetails');
export const editProjectPlanner = () => import('./project-planner/editProjectPlanner');
export const deleteProjectPlanner = () => import('./project-planner/deleteProjectPlanner');
export const uploadCoverImage = () => import('./project-planner/uploadCoverImage');
export const listCoverImages = () => import('./project-planner/listCoverImages');
